import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from "primereact/api";
import { InputSwitch } from "primereact/inputswitch";

const FreDataTable = ({ data,
  columns,
  actionButtonBaseUrl,
  routeIdField,
  actionButtonViewToggleField = null,
  inactiveToggle = { canToggle: false, initialValue: false, fieldName: 'isActive', toggleText: 'Show Inactive Apps' }
}) => {

  const actionButtonTemplate = (row) => {
    return <Link
      data-test-id="goToRow"
      to={actionButtonBaseUrl + row[routeIdField]}
      className={`btn btn-${!actionButtonViewToggleField || (actionButtonViewToggleField && row[actionButtonViewToggleField]) ? 'primary' : 'secondary'}`}
    >
      {!actionButtonViewToggleField || (actionButtonViewToggleField && row[actionButtonViewToggleField]) ? 'Edit' : 'View'}
    </Link>
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    ...columns.map(column => {
      return { [column.fieldName]: { value: null, matchMode: FilterMatchMode.CONTAINS } }
    })
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" data-test-id="datatable-search-input" />
        {inactiveToggle.canToggle && <InputSwitch data-test-id="inactive-toogle" className="ml-2 mt-2" checked={showInactive} onChange={(e) => setShowInactive(e.value)} />}
        {inactiveToggle.canToggle && <span className="ml-2 mt-2">{inactiveToggle.toggleText}</span>}
      </div>
    );
  };

  const isActiveTemplate = (row) => {
    return <span className={row[inactiveToggle.fieldName] ? 'text-success' : 'text-danger'}>{row[inactiveToggle.fieldName] ? 'Active' : 'Inactive'}</span>;
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const filterFields = columns.filter(col => !col.disableSearch).map(col => col.fieldName);
  const columnNodes = columns.map(col => {
    if (col.fieldName === inactiveToggle.fieldName) {
      return <Column hidden={col.hidden} key={col.fieldName} field={col.fieldName} sortable={!col.disableSort} header={col.headerName} body={isActiveTemplate} />;
    }
    if (col.body) {
      return <Column hidden={col.hidden} key={col.fieldName} field={col.fieldName} sortable={!col.disableSort} header={col.headerName} body={col.body} />;
    }
    return <Column hidden={col.hidden} key={col.fieldName} field={col.fieldName} sortable={!col.disableSort} header={col.headerName} />;
  });

  const [showInactive, setShowInactive] = useState(!inactiveToggle.canToggle || inactiveToggle.initialValue);
  const header = renderHeader();
  const footer = `In total there are ${data.length} items.`;

  return (<DataTable value={showInactive ? data : data.filter(app => app[inactiveToggle.fieldName])} showGridlines stripedRows paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100, 250]}
    filters={filters} globalFilterFields={filterFields} filterDisplay="row" emptyMessage="No results found."
    header={header} size="small" footer={footer} tableStyle={{ minWidth: '50rem' }} data-test-id="datatable">
    {columnNodes}
    <Column field={inactiveToggle.fieldName ?? ''} header='' body={actionButtonTemplate} />
  </DataTable>);
};

FreDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      disableSort: PropTypes.bool,
      hidden: PropTypes.bool,
      disableSearch: PropTypes.bool,
      body: PropTypes.func
    })).isRequired,
  actionButtonBaseUrl: PropTypes.string.isRequired,
  routeIdField: PropTypes.string.isRequired,
  actionButtonViewToggleField: PropTypes.string,
  inactiveToggle: PropTypes.shape({ canToggle: PropTypes.bool, initialValue: PropTypes.bool, fieldName: PropTypes.string, toggleText: PropTypes.string })
};

export default FreDataTable;
